import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

export default (props) => {
  const [details, setDetails] = useState(false);
  const [tryItToggle, setTryItToggle] = useState(false);
  const [executed, setExecuted] = useState(undefined);

  const location = window.location.host;

  const toggleDetails = () => setDetails((d) => !d);
  const toggleTryIt = () => setTryItToggle((e) => !e);

  const execute = async () =>
    setExecuted(
      await (await fetch(`https://${location}${props.endpoint}`)).json()
    );

  return (
    <div
      className={`rounded border-2 text-black w-full flex flex-col ${
        props.method === "GET"
          ? "border-blue-400 hover:border-blue-700 bg-blue-200"
          : "border-green-600 hover:border-green-800 bg-green-200"
      } ${props.className}`}
    >
      <div
        className="flex w-full gap-4 p-2 items-center cursor-pointer"
        onClick={!props.disabled ? toggleDetails : undefined}
      >
        <div
          className={`rounded ${
            props.method === "GET" ? "bg-blue-500" : "bg-green-600"
          } px-5 py-1 text-white`}
        >
          {props.method}
        </div>
        <span className="font-mono">{props.endpoint}</span>
        <span className="font-light text-sm hidden sm:inline">
          {props.description}
        </span>
        <div className="flex-1 flex justify-end pr-4">
          <FontAwesomeIcon icon={faAngleDown} />
        </div>
      </div>
      {details && <div className="w-full bg-blue-400 h-[2px]" />}
      {details && (
        <div>
          <div className="py-6 px-4 font-light">{props.body_description}</div>
          <div className="py-4 px-4 bg-blue-100 flex justify-between items-center">
            <span className="font-light">Parameters</span>
            <button
              className={`rounded border-2 ${
                tryItToggle ? "border-red-500 text-red-500" : "border-gray-500"
              } px-4 font-semibold`}
              onClick={toggleTryIt}
            >
              {tryItToggle ? "Cancel" : "Try it out"}
            </button>
          </div>
          <div className="px-4 pt-2 pb-4 flex items-start flex-col">
            <div className="w-full">
              {props.details && props.details.length > 0 ? (
                <>
                  <table className="w-full">
                    <thead>
                      <tr className="border-b-[#aeb8ba] border-b">
                        <th className="p-2">Name</th>
                        <th className="text-sm w-full text-left p-2 pl-4">
                          Description
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.details.map((detail) => (
                        <tr>
                          <td className="font-bold align-baseline pl-4">
                            <div>{detail.name}</div>
                          </td>
                          <td className="flex flex-col font-base text-sm p-4">
                            <span className="font-light text-lg mb-4">
                              {detail.description}
                            </span>
                            {detail.json && (
                              <>
                                <span className="mt-4 mb-2 font-medium">
                                  Example value | Model
                                </span>
                                <pre className="bg-zinc-800 text-white mt-2 rounded p-2 text-xs whitespace-pre-wrap">
                                  {JSON.stringify(detail.json, null, 2)}
                                </pre>
                              </>
                            )}
                            {detail.text && (
                              <>
                                <input
                                  type="text"
                                  className="p-2 border-2"
                                  disabled={!tryItToggle}
                                  defaultValue={detail.text.placeholder}
                                />
                              </>
                            )}
                            {detail.types && detail.types.length > 0 && (
                              <>
                                <span className="font-bold text-sm mt-2">
                                  Parameter content type
                                </span>
                                <select className="p-2 border-2 border-black rounded font-bold">
                                  {detail.types.map((type) => (
                                    <option>{type}</option>
                                  ))}
                                </select>
                              </>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : (
                <>No parameters</>
              )}
            </div>
            {tryItToggle ? (
              <div className="w-full">
                <button
                  onClick={execute}
                  disabled={props.maintenance != null}
                  className={`bg-blue-500  border-blue-500 border-2 transition-colors ${
                    executed ? "w-1/2 rounded-l" : "w-full rounded"
                  } ${
                    !props.maintenance ? "hover:bg-blue-600" : "bg-red-300"
                  } px-4 py-0.5 mt-4 text-white font-bold`}
                >
                  Execute{" "}
                  <span className="font-light">{props.maintenance}</span>
                </button>
                {executed && (
                  <button
                    onClick={execute}
                    className={`border-2 border-neutral-500 transition-colors ${
                      executed ? "w-1/2" : "w-full"
                    } rounded-r px-4 py-0.5 mt-4 text-neutral-800 font-bold`}
                  >
                    Clear
                  </button>
                )}
              </div>
            ) : (
              <div className="h-4" />
            )}
          </div>
          <div className="py-2 px-4 bg-blue-100 flex justify-between items-center">
            <span className="font-light">Responses</span>
          </div>
          {executed && (
            <div>
              <div className="px-4 py-2 mt-4">
                <span className="text-xs font-bold">Curl</span>
                <pre className="bg-zinc-800 text-white rounded p-2 text-xs whitespace-pre-wrap">
                  {`curl -X 'GET' \\
  'https://${location}${props.endpoint}' \\
  -H 'accept: application/json'`}
                </pre>
                <span className="text-xs font-bold">Request URL</span>
                <pre className="bg-zinc-800 text-white rounded p-2 text-xs whitespace-pre-wrap">
                  https://{location}
                  {props.endpoint}
                </pre>
              </div>
              <div className="py-2 px-4 text-xs font-bold">Server response</div>
              <div className="py-2 px-4 flex justify-between items-center">
                <table className="w-full">
                  <thead>
                    <tr>
                      <th>Code</th>
                      <th className="text-sm w-full text-left pl-4">
                        Description
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="font-normal">200</td>
                      <td className="flex flex-col font-base text-sm p-4">
                        successful operation
                        <span className="mt-4 mb-2 font-medium">
                          Response body
                        </span>
                        <pre className="bg-zinc-800 text-white mt-2 rounded p-2 text-xs whitespace-pre-wrap">
                          {JSON.stringify(executed, null, 2)}
                        </pre>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="py-2 px-4 text-xs font-bold">Responses</div>
            </div>
          )}
          <div className="py-2 px-4 flex justify-between items-center">
            <table className="w-full">
              <thead>
                <tr>
                  <th>Code</th>
                  <th className="text-sm w-full text-left pl-4">Description</th>
                </tr>
              </thead>
              <tbody>
                {props.responses.map((response, index) => (
                  <tr key={index}>
                    <td className="font-normal">{response.code}</td>
                    <td className="flex flex-col font-base text-sm p-4">
                      {response.description}
                      <span className="mt-4 mb-2 font-medium">
                        Example Value{" "}
                        <span className="font-light">| Schema</span>
                      </span>
                      <pre className="bg-zinc-800 text-white mt-2 rounded p-2 text-xs whitespace-pre-wrap">
                        {JSON.stringify(response.json, null, 2)}
                      </pre>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};
