import { useState } from "react";
import "./App.css";
import SwaggerCard, { SwaggerBody } from "./components/SwaggerCard";
import Turnstile from "react-turnstile";

export default () => {
  const [verified, setVerified] = useState(false);

  const handleVerify = () => setVerified(true);

  return (
    <div className="text-white">
      <nav className="h-14 bg-gray-200 dark:bg-neutral-900 flex items-center justify-between px-4 font-mono text-black dark:text-white">
        <div>
          <img
            className="h-10 inline-block mr-2"
            src="https://avatars.githubusercontent.com/u/29690431?v=4"
          />
          <span>iloveopensource.dev</span>
        </div>
      </nav>
      <div className="bg-gray-300 dark:bg-neutral-700 p-4 sm:p-8 text-black dark:text-white">
        <div className="relative w-fit">
          <h1 className="text-3xl font-bold font-mono">OpenSource API</h1>
          <div className="absolute top-0 -right-16 bg-neutral-500 px-2 rounded-full font-bold text-sm text-white">
            1.0.1
          </div>
        </div>
        <Turnstile
          className="mt-4"
          sitekey="0x4AAAAAAABIvVowrqtCxxhn"
          onVerify={handleVerify}
        />
        <p className="mt-4 text-sm">
          Hi and welcome to my website! My name is Dejan Žegarac, also known
          online as OpenSource. To learn more about me, please use the API
          endpoints below.
        </p>
      </div>
      <div className="max-w-[92rem] items-center mx-auto gap-12 flex text-black/75 dark:text-white/75 p-5 sm:p-8">
        <div className="w-full bg-scroll flex gap-6 flex-col items-center h-5/6">
          <SwaggerCard
            method="GET"
            endpoint="/api/v1/welcome"
            disabled={!verified}
            description="Gets the welcome message."
            body_description="Gets the welcome message and basic info about me."
            responses={[
              {
                code: 200,
                description: "The response you get when everything is OK.",
                json: [
                  {
                    welcome: "string",
                    about: {
                      name_first: "string",
                      name_last: "string",
                      age: "number",
                      location: {
                        country: "string",
                        city: "string",
                      },
                    },
                    social: {
                      discord: "string",
                      github: "string",
                      linkedin: "string",
                    },
                  },
                ],
              },
            ]}
          />
          <SwaggerCard
            method="GET"
            endpoint="/api/v1/experience"
            disabled={!verified}
            description="Gets the list of companies I've worked for."
            body_description="The list of companies I've worked for."
            responses={[
              {
                code: 200,
                description: "The response you get when everything is OK.",
                json: [
                  {
                    company_name: "string",
                    job_title: "string",
                    technologies: ["string"],
                    from: "date",
                    to: "date",
                  },
                ],
              },
            ]}
          />
          <SwaggerCard
            method="GET"
            endpoint="/api/v1/projects"
            disabled={!verified}
            description="Gets the list of projects"
            body_description="Gets the list of private projects that I work on in my spare time"
            responses={[
              {
                code: 200,
                description: "The response you get when everything is OK.",
                json: [
                  {
                    name: "string",
                    description: "string",
                    technologies: ["string"],
                    github: "string",
                    website: "string",
                    from: "date",
                    to: "date",
                  },
                ],
              },
            ]}
          />
          <SwaggerCard
            method="POST"
            endpoint="/api/v1/contact"
            description="Submits a contact form"
            className="mt-4"
            disabled={!verified}
            maintenance={
              "This endpoint is currently disabled due to technical issues. Please use dejan@thearcadia.xyz to reach out while I work with CloudFlare on a solution."
            }
            body_description="Submits a contact form"
            details={[
              {
                name: "email",
                description: "Your email",
                /*json: {
                  email: "string",
                  title: "string",
                  message: "string",
                },*/
                text: {
                  placeholder: "dejan@thearcadia.xyz",
                },
                //types: ["application/json"],
              },
              {
                name: "title",
                description: "The email title",
                text: {
                  placeholder: "An important business inquiry",
                },
              },
              {
                name: "message",
                description: "The message you're willing to send",
                text: {
                  placeholder: "Hello, are you selling pizza?",
                },
              },
            ]}
            responses={[
              {
                code: 200,
                description: "The response you get when everything is OK.",
                json: [
                  {
                    name: "string",
                    description: "string",
                    technologies: ["string"],
                    github: "string",
                    website: "string",
                    from: "date",
                    to: "date",
                  },
                ],
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};
